import { Injectable } from '@angular/core';
import { HttpService } from '@app/core/http';

import {
  ProjectList,
  SetSelectedProject,
  WebMilestoneList,
  WebMilestoneListClear,
} from '@app/store/actions/website-action/manage-project.action';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import { tap } from 'rxjs';

interface IManageProjectStateModel {
  projectList: IProjectList | ICareProjectList[];
  projectListCount: number;
  selectedProjectData: IProjectList | null;
  webMilestoneList: IWebMilestone[];
  webMilestoneListCount: number;
}
@State<IManageProjectStateModel>({
  name: 'ManageProjectState',
  defaults: {
    projectList: [],
    projectListCount: 0,
    selectedProjectData: null,
    webMilestoneList: [],
    webMilestoneListCount: 0,
  },
})
@Injectable()
export class ManageProjectState {
  constructor(private _http: HttpService, private _toastr: ToastrService) {}

  @Selector()
  static projectList(state: IManageProjectStateModel) {
    return state.projectList;
  }

  @Selector()
  static projectListCount(state: IManageProjectStateModel) {
    return state.projectListCount;
  }

  @Selector()
  static selectedProjectData(state: IManageProjectStateModel) {
    return state.selectedProjectData;
  }

  @Selector()
  static webMilestoneList(state: IManageProjectStateModel) {
    return state.webMilestoneList;
  }

  @Action(SetSelectedProject)
  SetSelectedProject(
    ctx: StateContext<IManageProjectStateModel>,
    { param }: SetSelectedProject
  ) {
    return ctx.patchState({
      selectedProjectData: param,
    });
  }

  @Action(ProjectList)
  ProjectList(
    ctx: StateContext<IManageProjectStateModel>,
    { param }: ProjectList
  ) {
    return this._http.post('projects/list', param).pipe(
      tap((apiResult) => {
        const resultData = apiResult.response.dataset;

        ctx.patchState({
          projectList: resultData.projects,
          projectListCount: resultData.total_rows,
        });
      })
    );
  }

  @Action(WebMilestoneList)
  WebMilestoneList(
    ctx: StateContext<IManageProjectStateModel>,
    { param }: WebMilestoneList
  ) {
    return this._http.post('website/view-milestones', param).pipe(
      tap((apiResult) => {
        const resultData = apiResult.response.dataset;

        ctx.patchState({
          webMilestoneList: resultData.milestones,
          webMilestoneListCount: resultData.total_rows,
        });
      })
    );
  }

  @Action(WebMilestoneListClear)
  WebMilestoneListClear(ctx: StateContext<IManageProjectStateModel>) {
    ctx.patchState({
      webMilestoneList: [],
      webMilestoneListCount: 0,
    });
  }
}
